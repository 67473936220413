body {
    position: relative;
    max-width: 100%;
    max-width: 100vw;
    margin: 0;
    padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
    color: $color-fg;
    background: $color-bg;
    -webkit-text-size-adjust: none;
    overflow-x: hidden;
    overflow-y: auto;
    @media print {
        color: #000;
        background: #fff;
    }
}
* {
    box-sizing: border-box;
}
::selection {
    color: #fff;
    background-color: $color-primary;
}

a {
    color: $color-primary;
    text-decoration: none;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

// TYPOGRAPHY

html,body {
    font-size: $font-size-base;
    font-family: $font-default;
    line-height: $line-height-default;
    @media screen AND (min-width:$md-and-up) {
        font-size: 20px;
    }
    @media screen AND (min-width:$lg-and-up) {
        font-size: 22px;
    }
}

/* stacks */

h1,
h2 {
    @include typo-smallcaps();
    line-height: $line-height-header;
    margin: 1em 0 0.5em;
}
h3 {
    @include typo-smallcaps();
    line-height: $line-height-header;
    margin: 1.1em 0;
}
h4 {
    @include typo-smallcaps();
}
h1,h2,h3,h4 {
    b,strong {
        @include typo-smallcaps();
    }
}
h5,
h6 {
    @include typo-emphasis();
}

/* sizes */
h6 {
    font-size: $font-size-sm;
}
h5 {
    font-size: $font-size-md;
}
h4 {
    font-size: $font-size-md2;
}
h3 {
    font-size: $font-size-lg;
    @media (max-width: $less-than-xs) {
        font-size: $font-size-lg-sm;
    }
}
h2 {
    font-size: $font-size-xl;
    @media (max-width: $less-than-xs) {
        font-size: $font-size-xl-sm;
    }
}
h1 {
    font-size: $font-size-xxl;
    @media (max-width: $less-than-xs) {
        font-size: $font-size-xxl-sm;
    }
}
.clearmargin {
    @include clearmargin();
}
.clearfix {
    @include clearfix();
}

.Icon-Store {
    height: 0;
    overflow: hidden;
}