// JS: Object fit
// 
// ``object-fit`` and ``object-position`` are CSS properties that allow images act like the common ``background-size:cover`` and ``background-position: 100% 50%`` properties, but on ``IMG`` and ``PICTURE`` elements.
// If an ``IMG`` has an enforced size of 500x200px, ``object-fit`` will allow the image to fill that space and even choose which part of the image to focus on. 
// This is often more flexible than using ``background-size``, because we can use it in conjunction with ``SRCSET`` or ``PICTURE`` to allow responsive images without resorting to writing custom CSS in the view or JS. 
// But support for ``object-fit`` is not across-the-board, and existing polyfills are occasionally brittle when interacting with other techniques (eg lazyload), so this project has it's own polyfill.
// The system carries out the following lifecycle:
// 1. On page load/script initialisation, each element in the page that has a datatype of ``data-js-poly-object-fit`` is considered by the polyfill.
// 2. If the browser supports ``object-position``, the polyfill ends here. It checks for ``object-position`` because some iOS versions support ``object-fit`` but not ``object-position``.
// 3. If the browser does not support ``object-position``, it checks to see if it is a lazyloaded image (that has not yet been processed). If it is, it adds an event to wait for that lazyload event to happen before proceeding (and adds a class to the wrapper of ``ObjectFit--lazyload``). If it is not a lazyload image, it continues straight away.
// 4. When the polyfil is instructed to run, it takes the ``currentSrc`` of the image (or ``src`` if it's not suppported by the browsers, but ``currentSrc`` is preferred because it will take the right file for a responsive image), and sets it as the background of the containing element.
// 5. The container wrapping already has classes that determine that any image background will be the correct ``background-size`` and ``background-position``, so it mimics the effect of an object-fit element.
// 6. The image itself is given a class of ``ObjectFit--hidden``, which in effect sets ``visibility:hidden``, revealing the image in the background.
// 7. 50 ms later it adds a class of ``.ObjectFit--done`` (to fade in any lazy loaded images)
// ***
// The pattern to trigger and successfully run the polyfill is below. Note that any of the position classes that are added to the image, are reflected in the container too, so that when the polyfill runs, it's background image is in the same place as the object-fit image would be.
// ```
// <span class="ObjectFit Position--right--top" data-js-poly-object-fit>
//     <img src="/path/to/default.jpg" srcset="/path/to/small.jpg 600w, /path/to/large.jpg 1200w" sizes="(max-width: 600px) 50vw, 100vw" class="THISMODULECLASS Position--right--top" />
// </span>
// ```
// Just to re-iterate, this polyfill will run in conjunction with the lazysizes technique used in this project.
// ***
// **NOTES**
// - If you would normally place any particulat position styling on the image (floating, block etc), you'll need to do this on the container instead. 
// - It might be worth wrapping a span around the image soley for the purpose of this polyfill, do keep it clean of any other structural markup you have in the page.
//
// markup:
// <iframe src="http://caniuse.com/object-fit/embed" style="width: 100%;height: 365px;max-width:1200px;display: block; margin: 20px auto;" scrolling="no" frameborder="no"></iframe>
// 
// Styleguide 5.4.

.ObjectFit {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;
    img {
        display: block; // if the image is "inline" or "inline-block", any whitespace around it will be interpreted as a blank space, resulting in unexpected results.
    }
    &--lazyload {
        opacity: 0;
        transition: opacity 500ms;
    }
    &--done {
        opacity: 1;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &--hidden {
        visibility: hidden;
    }
}