@import "../../base/variables-colors";
@import "../../base/variables-typography";
@import "../../base/variables-breakpoints";
@import "../../base/variables";
@import "../../base/mixins";

.Text {
    &--primary {
        color: $color-primary;
        @supports ( animation:none ) {
            opacity: 0;
            animation: fade-in 1000ms forwards;
            animation-delay: 1500ms;
        }
        @media print {
            color: $color-grey5;
        }
    }
    &-Inner {
        display: flex;
        justify-content: space-around;
        max-width: $width-sm;
        margin: 0 auto;
        font-size: 1rem;
        &:before {
          display: none;
        }
        @media (max-width: $less-than-xs) {
          display: block;
        }
        &--xxl {
          max-width: 9000px;
          max-width: 100vw;
        }
        &--xl {
          max-width: $width-xl;
        }
        &--lg {
          max-width: $width-lg;
        }
        &--md {
          max-width: $width-md;
        }
        &--sm {
          // note this is the default
        }
        &--xs {
          max-width: $width-xs;
        }
        &--xxs {
          max-width: $width-xxs;
        }

        &--type-sm {
          font-size: $font-size-sm;
          line-height: 1.4;
        }
        &--type-md2 {
          font-size: $font-size-md2;
          line-height: 1.3;
        }
        @media print {
            max-width: $print-width !important;
        }
    }
    &-Main-Col {
      &+.Text-Main-Col {
        @media (max-width: $less-than-xs) {
          margin-top: $gutter-section-sm-sm;
        }
      }
    }
}

// rather than include a whole font for two small sans-serif titles, we fallback to the regular sans-serif which already has a bold weight.
.Rte {
  b {
    font-size: 1.05em;
  }
}