// Vertical sections
//
// Status: near final
//
// A pattern to bring reliable, repeatable vertical rhythm and consistent centered column containers. Each section has a standard margin above it, providing control over spacing between sections.
//
// A section is based around an outer and an inner div. The outer should always be the width of the page (well, it also controls the page edge gutter, so maybe not the **whole** width), and controls the vertical margin between the previous section and this one. The inner div controls the width of the central column.
//
// .Section - a simple section that handles vertical spacing, and an inner container that handles column width.
// 
// markup:
// <div class="Section">
//     <div class="Section-Inner">
//         <h2>This is a section container</h2>
//         Eiusmod eu id commodo labore incididunt anim consectetur deserunt amet minim labore non nostrud irure enim consectetur. Ad nisi laborum laborum nulla aute consectetur sunt ut aute mollit deserunt aute ullamco nostrud. Est tempor irure Lorem exercitation id ullamco nulla. Excepteur do enim in et occaecat veniam exercitation ea.
//     </div>
// </div>
//
// Styleguide 4.2.

// Section margin options
//
// By default, margins between sections are pretty wide. There are two smaller margin options plus a fully collapsed margin if you need a block to be right up next to the preceding block. Most of these options have a smaller option for small/shallow screens.
//
// .Section - standard top margin
// .Section.Section--sm - smaller top margin
// .Section.Section--xs - smallest top margin
// .Section.Section--no-margin - no top margin
// 
// markup:
// <div class="{$modifiers}">
//     <div class="Section-Inner">
//         <h2>This is a section container</h2>
//         Eiusmod eu id commodo labore incididunt anim consectetur deserunt amet minim labore non nostrud irure enim consectetur. Ad nisi laborum laborum nulla aute consectetur sunt ut aute mollit deserunt aute ullamco nostrud. Est tempor irure Lorem exercitation id ullamco nulla. Excepteur do enim in et occaecat veniam exercitation ea.
//     </div>
// </div>
//
// Styleguide 4.2.1.

// Section column widths
//
// The section inner div can have a variety of widths, all from the standard width variables (and with the same names).
// 
// .Section-Inner - default, *LG* size (980px)
// .Section-Inner.Section-Inner--xs - up to an *XS* size column (35rem)
// .Section-Inner.Section-Inner--sm - up to an *SM* size column (40rem)
// .Section-Inner.Section-Inner--md - up to an *MD* size column (50rem)
// .Section-Inner.Section-Inner--lg - up to an *LG* size column (980px)
// .Section-Inner.Section-Inner--xl - up to an *XL* size column (1200px)
// .Section-Inner.Section-Inner--xxl - full width (or at least max size)
// 
// markup:
// <div class="Section">
//     <div class="{$modifiers}">
//         <h2>This is a section container</h2>
//         Eiusmod eu id commodo labore incididunt anim consectetur deserunt amet minim labore non nostrud irure enim consectetur. Ad nisi laborum laborum nulla aute consectetur sunt ut aute mollit deserunt aute ullamco nostrud. Est tempor irure Lorem exercitation id ullamco nulla. Excepteur do enim in et occaecat veniam exercitation ea.
//     </div>
// </div>
//
// Styleguide 4.2.2.

.Section {
    margin: $gutter-section-md-sm $gutter 0;
    page-break-inside: avoid;
    @media (min-width: $xs-and-up) and (min-height: $h-md-and-up) {
        margin: $gutter-section-md $gutter 0;
    }

    // iPhone x landscape
    margin-left:  calc(#{$gutter} - env(safe-area-inset-left));
    margin-right: calc(#{$gutter} - env(safe-area-inset-right));
    @media (min-width: $xs-and-up) {
        padding-left:  env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }

    // alternative margin sizes
    &--lg {
        margin-top: $gutter-section-lg-sm;
        @media (min-width: $xs-and-up) and (min-height: $h-md-and-up) {
            margin-top: $gutter-section-lg;
        }
    }
    &--sm {
        margin-top: $gutter-section-sm-sm;
        @media (min-width: $xs-and-up) and (min-height: $h-md-and-up) {
            margin-top: $gutter-section-sm;
        }
    }
    &--xs {
        margin-top: $gutter-section-xs-sm;
        @media (min-width: $xs-and-up) and (min-height: $h-md-and-up) {
            margin-top: $gutter-section-xs;
        }
    }
    @media print {
        margin-top: $print-margin;
    }
    &--no-margin {
        margin-top: 0;
    }

    // sepcific uses
    &--center {
        text-align: center;
        @media print {
            text-align: left;
        }
    }
    &--justify-xxs {
        @media screen AND (max-width: $less-than-xs) {
            text-align: justify;
        }
    }
    &--padding {
        &-sm {
            padding-top: $gutter-section-sm-sm;
            padding-bottom: $gutter-section-sm-sm;
        }
        &-xs {
            padding-top: $gutter-section-xs-sm;
            padding-bottom: $gutter-section-xs-sm;
        }
    }

    &-Inner {
        @include clearmargin();
        max-width: $width-lg;
        margin-left: auto;
        margin-right: auto;
        
        &--xxl {
            max-width: 9000px;
            max-width: 100vw;
        }
        &--xl {
            max-width: $width-xl;
        }
        &--lg {
            max-width: $width-lg;
        }
        &--md {
            max-width: $width-md;
        }
        &--sm {
            max-width: $width-sm;
        }
        &--xs {
            max-width: $width-xs;
        }
        // specials
        &--caged {
            border: 1px solid #ddd;
            border-radius: 10px;
            padding: $gutter;
        }
        &>h1,
        &>h2,
        &>h3,
        &>h4,
        &>h5,
        &>h6,
        &>p {
            &:first-child {
                margin-top: 0;
            }
        }
        &>h2 {
            &:first-child {
                margin-top: -0.25em;
            }
        }
        @media print {
            max-width: $print-width;
        }
    }

}