// JS: lazyloading
// 
// To aid paload performance, we lazyload most images (apart from heros that are at the top of the page so would just appear slower if they were lazyloaded). 
// We've built our own lazyloading script that interacts with intersection observer, so that we can control the system and use the latest scroll performance techniques.
// It's vanilla-JS, but does hook into the element binding system in this project. It is built to have exactly the same hooks and data attributes as the common [lazysizes](http://afarkas.github.io/lazysizes/index.html) library, to allow easy transition between the two systems. 
// The system carries out the following lifecycle:
// 1. On page load/script initialisation, a lazyload instance is attached to each element in the page that has a class of ``.lazyload``
// 2. As the element is initialised (normally at page load time), the script waits a frame (see RAF) then determines if it's an ``ÌMG``, ``PICTURE``, or an element with a ``background-image``. It collects the URLs to image files that are in data attributes and stores them in memory to reduce any DOM lookup later when page scrolling should be taking up CPU time.
// 3. The element is then handed off to Intersection Observer, which will determine if 10% of the area 50px around the element is visible (``rootMargin`` and ``threshold`` are two settings within intersection observer).
// 4. When Intersection Observer determines the threshold of visibility has be reached, it sets the status of the element to true (so that it's never triggered from this point on), and fills in the ``SRC``/``SRCSET``/``STYLE`` of the element (relevent to the type of element it is). After this, it triggers a custom event on the element ``imageLoaded`` - specifically used by the object fit polyfill to do it's stuff. It also adds ``.lazyloading`` class, to enable any visual CSS updates.
// 5. Once the image file has fully loaded, the script changes the ``.lazyloading`` class to ``.lazyloaded``, to show that it has been completed.
// 6. Once any fade in is complete, it removed the ``.lazyload`` class from the element.
// ***
// Note that ``NOSCRIPT`` alternatives are included so that even if JS doesn't load (search engines, screen readers, etc), the image still works. ***PLEASE*** include widths and heights on these images, even if they are not the right size for any particular page (all modules have CSS overrides to restrict images breaking layouts). Including image widths and heights can be used by browser engines and by JS helpers in this site to improve the user experience greatly.
// All the following examples will work.
// ***
// **regular IMG tag**
// ```
// <img data-src="/path/to/image.jpg" width="XXX" height="XXX" alt="hej!" class="lazyload" /><noscript><img src="/path/to/image.jpg" /></noscript>
// ```
// **SRCSET IMG tag**
// ```
// <img data-src="/path/to/default.jpg" data-srcset="/path/to/small.jpg 600w, /path/to/large.jpg 1200w" sizes="(max-width: 600px) 50vw, 100vw" width="XXX" height="XXX" alt="hej!" class="lazyload" /><noscript><img src="/path/to/default.jpg" /></noscript>
// ```
// **PICTURE tag**
// ```
// <picture class="lazyload">
//     <source data-srcset="/path/to/small.jpg" media="(max-width: 599px)" />
//     <source data-srcset="/path/to/large.jpg" media="(min-width: 600px)" />
//     <img src="/path/to/small.jpg" width="XXX" height="XXX" alt="hej!" /> <!-- note the fallback isn't lazyloaded -->
// </picture>
// ```
// **DIV bacground**
// ```
// <div class="lazyload" data-bg="/path/to/background.jpg"></div>
// ```
// 
// .
// 
// Styleguide 5.3.

// JS: lazyloading strut
// 
// One of the problems with lazyloading is that the browser has no idea what the size of the final image is before it's loaded - especially in a resonsive site where the width and height can be fluid depending on the screen size. And that means as you scroll down the page and images are discovered and loaded, the page jumps around as the images expand and the content reflows. This is one of those semi-conscious things that gives the web that "ugh, web pages" feeling to people that would rather be in an app. So let's kill it with fire.
//
// If we wrap a lazyloaded **IMG** or **PICTURE** with a container that has the ``.lazyload-strut``, and the image has ``width="XXX" height="XXX"`` (the sizes don't need accurate for any particular screen size, they just need to be the correct natural width vs height aspect ratio of the image).
//
// At script initialisation (paload time), the lazyload script scans the page for those images with strut wrappers, collects the width/height of the image, calculates the aspect ratio and sets up the wrapper with a [padding-top hack](http://css-tricks.com/aspect-ratio-boxes/). This reserves the space that the image will later load into, reducing page re-layout and content re-flow.
//
// Once an image wrapper is *strutted*, it gets the class ``.lazyload-strut--ready``, and once all potential struts on the page are completed, the app is sent a ``lazyload-struts-ready`` event. This is particularly used by the masonry function in [card grids](/patterns/#!/section/3.22) as an indication that it's safe to start measuring image elements.
// - Note that we only strut the height, the width is almost always determined by the module CSS.
// - Many modules have fixed ratios on images (eg, 16:9 is the default for the [images block](/patterns/#!/section/3.15)), and this lazyloading strut is not needed in those cases as basically the same thing is baked into the module.
// 
// .
//
// markup:
// <span class="lazyload-strut">
//     <img data-src="static/imgs/dusk-md.jpg" 
//        data-srcset=" /static/imgs/dusk-lg.jpg 1600w, /static/imgs/dusk-md.jpg 1100w, /static/imgs/dusk-sm.jpg 600w" 
//        width="1100" 
//        height="743" 
//        alt="test image alt" 
//        class="lazyload" />
//     <noscript>
//       <img src="static/imgs/dusk-md.jpg" alt="" class="" />
//     </noscript>
// </span>
// 
// sg-wrapper:
// <div class="Section-Inner" style="padding:20px;text-align:center;">
//     <sg-wrapper-content/>
// </div>
//
// Styleguide 5.3.1.


img,
picture {
    &.lazyload {
        opacity: 0;
        transition: opacity 500ms;
        &ed {
            opacity: 1;
        }
    }
}
.lazyload {
    &-strut {
        &--ready {
            display: block;
            position: relative;
            overflow: hidden;
            img,
            picture {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

